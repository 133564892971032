import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "mail",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1795",
      "data-name": "Rechteck 1795",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "mail-2",
      "data-name": "mail",
      d: "M790.1,93.617h-16a2,2,0,0,0-2,2v10a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2v-10A2,2,0,0,0,790.1,93.617Zm-2.3,2-5.7,3.8-5.7-3.8Zm-13.7,10V96.486l7.445,4.964a1,1,0,0,0,1.109,0l7.446-4.964v9.131Z",
      transform: "translate(-770.1 -88.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}