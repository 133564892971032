
import { useHelpersStore } from "@/store/helpers";
import { UserInterface } from "@/store/settings/interfaces";
import { storeToRefs } from "pinia";
import { computed, defineComponent } from "vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import AvatarContainer from "../../../../components/helpers/avatarContainer/AvatarContainer.vue";
import IconEdit from "../../../../components/icons/IconEdit1.vue";
import IconMail from "../../../../components/icons/IconMail.vue";

export default defineComponent({
    name: "settingsUserContainer",
    components: { AvatarContainer, IconEdit, IconMail, LoadingAnimation },
    props: {
        user: {
            type: Object as () => UserInterface,
            required: true,
        },
    },
    emit: ["editUser", "resendInvite"],
    setup(props) {
        const { loadingIds } = storeToRefs(useHelpersStore());
        const isAdmin = computed(() => {
            return props.user?.role_ids?.find((roleId) => roleId == 1);
        });

        const isLoading = computed(() => {
            return loadingIds.value.some(
                (id) => id.id == "resend-mail-" + props.user.id
            );
        });

        return { isAdmin, isLoading };
    },
});
