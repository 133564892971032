
import { computed, defineComponent, ref, watch } from "vue";
import CenteredModal from "../../../../components/layout/modals/CenteredModal.vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import InputDefault from "../../../../components/inputs/inputDefault/InputDefault.vue";
import InputSelect from "../../../../components/inputs/inputSelect/InputSelect.vue";
import IconTrash from "../../../../components/icons/IconTrash.vue";
import DeleteModal from "../../modals/ConfirmDeleteModal.vue";
import { UserInterface } from "@/store/settings/interfaces";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useUserStore } from "@/store/settings/userStore";
import { useRoleStore } from "@/store/settings/roleStore";

export default defineComponent({
    name: "settingsAddUserModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        InputSelect,
        IconTrash,
        DeleteModal,
    },
    props: {
        editUser: {
            type: Object as () => UserInterface,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, ctx) {
        const { createUser, updateUser, deleteUser } = useUserStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { roles } = storeToRefs(useRoleStore());
        const openDeleteModal = ref(false);
        const user = ref<UserInterface>({
            first_name: "",
            last_name: "",
            email: "",
            role_ids: [],
        });
        const isAllFilled = computed(() => {
            let isFilled = false;
            if (
                user.value.first_name &&
                user.value.last_name &&
                user.value.email
            ) {
                isFilled = true;
            }
            return isFilled;
        });

        watch(
            () => props.editUser,
            () => {
                user.value = JSON.parse(JSON.stringify(props.editUser));
            }
        );
        async function submitDeleteUser(): Promise<void> {
            setLoadingId("confirm-delete-button");
            const response = await deleteUser(props.editUser);
            removeLoadingId("confirm-delete-button");
            if (response?.status == 200) {
                toastMessageText.value = [
                    {
                        type: "success" as ToastMessageType,
                        message: "Benutzer wurde erfolgreich gelöscht.",
                    },
                ];
            }
            openDeleteModal.value = false;
            ctx.emit("close");
        }

        async function createNewUser(): Promise<void> {
            const finalUser: UserInterface = user.value;
            setLoadingId("save-button");
            if (props.editUser && props.editUser.id) {
                const response = await updateUser(finalUser);
                removeLoadingId("save-button");
                if (response?.status == 201) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message: "Benutzer wurde erfolgreich aktualisiert.",
                        },
                    ];
                    ctx.emit("close");
                }
            } else {
                const response = await createUser(finalUser);
                removeLoadingId("save-button");
                if (response?.status == 200 || response?.status == 201) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message: "Benutzer wurde erfolgreich angelegt.",
                        },
                    ];
                    ctx.emit("close");
                }
            }
        }
        return {
            isAllFilled,
            user,
            roles,
            createNewUser,
            submitDeleteUser,
            openDeleteModal,
        };
    },
});
