import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-shrink-0 justify-between items-center min-w-full w-full p-3 h-9 bg-white-dark hover:bg-grey-lightest rounded-md" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex md:w-56 w-32" }
const _hoisted_4 = { class: "text-sm ml-2 mr-1 truncate" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm md:flex hidden"
}
const _hoisted_6 = { class: "text-sm text-left md:flex hidden" }
const _hoisted_7 = { class: "flex gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar_container = _resolveComponent("avatar-container")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_icon_mail = _resolveComponent("icon-mail")!
  const _component_icon_edit = _resolveComponent("icon-edit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_avatar_container, {
        id: 'user-avatar',
        avatarStyle: { size: '7' },
        name: _ctx.user.full_name ? _ctx.user.full_name : '',
        src: _ctx.user.avatar_url
      }, null, 8, ["name", "src"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1),
        (_ctx.isAdmin)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "(Admin)"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.user.email), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.user.is_verified)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex items-center justify-center h-7 w-7 rounded-md hover:bg-grey-light cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('resendInvite')))
          }, [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_loading_animation, {
                  key: 0,
                  id: 'loadingAnimation',
                  name: 'loadingAnimation',
                  animationStyle: {
                        radius: '12',
                        animationColor: 'primary',
                    }
                }))
              : (_openBlock(), _createBlock(_component_icon_mail, {
                  key: 1,
                  color: 'primary'
                }))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "flex items-center justify-center h-7 w-7 rounded-md hover:bg-grey-light cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('editUser')))
      }, [
        _createVNode(_component_icon_edit, { color: 'primary' })
      ])
    ])
  ]))
}