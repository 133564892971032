
import { computed, defineComponent, onMounted, ref } from "vue";
import CustomButton from "../../../../components/inputs/customButton/CustomButton.vue";
import InputSearch from "@/components/inputs/inputSearch/InputSearch.vue";
import IconPlus from "../../../../components/icons/IconPlus.vue";
import UserContainer from "./UserContainer.vue";
import AddUserModal from "./AddUserModal.vue";
import loadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import { UserInterface } from "@/store/settings/interfaces";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/settings/userStore";
import { useRoleStore } from "@/store/settings/roleStore";
import { useI18n } from "vue-i18n";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "settingsUserAdministration",
    components: {
        CustomButton,
        IconPlus,
        UserContainer,
        AddUserModal,
        InputSearch,
        loadingAnimation,
    },
    setup() {
        const { t } = useI18n();
        const { setToastMessage, setLoadingId, removeLoadingId } =
            useHelpersStore();
        const { users } = storeToRefs(useUserStore());
        const { fetchUsers, resendEmail } = useUserStore();
        const { fetchRoles } = useRoleStore();
        const toEditUser = ref<UserInterface>({});
        const openAddUser = ref(false);
        const userSearch = ref("");
        const router = useRouter();

        function editUser(user: UserInterface) {
            toEditUser.value = user;
            openAddUser.value = true;
        }

        const filteredUsers = computed(() => {
            return users.value.filter((user: UserInterface) =>
                user.full_name
                    ?.toLowerCase()
                    .includes(userSearch.value.toLowerCase())
            );
        });
        const isLoading = ref(false);
        onMounted(async () => {
            users.value = [];
            isLoading.value = true;
            const response = await fetchUsers();
            isLoading.value = false;
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
            fetchRoles();
        });
        async function prepareResendEmail(user: UserInterface) {
            setLoadingId("resend-mail-" + user.id);
            const succeeded = await resendEmail(user);
            if (succeeded) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("toast_message.resend_email")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("toast_message.resend_email_failed")
                );
            }

            removeLoadingId("resend-mail-" + user.id);
        }
        return {
            editUser,
            filteredUsers,
            toEditUser,
            openAddUser,
            userSearch,
            prepareResendEmail,
            isLoading,
        };
    },
});
