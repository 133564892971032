import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:flex justify-between" }
const _hoisted_2 = { class: "lg:flex justify-between" }
const _hoisted_3 = { class: "lg:w-99 w-full" }
const _hoisted_4 = {
  key: 0,
  class: "mt-16 group"
}
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "text-red ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close'))),
        title: _ctx.editUser && _ctx.editUser.id ? _ctx.$t('save_user') : _ctx.$t('add_user'),
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'settings_container'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'save-button',
            class: "max-w-full",
            isDisabled: !_ctx.isAllFilled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createNewUser()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.editUser && _ctx.editUser.id ? _ctx.$t("save") : _ctx.$t("apply")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_input_default, {
              id: 'first_name',
              name: 'first_name',
              modelValue: _ctx.user.first_name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.first_name) = $event)),
              type: 'text',
              label: _ctx.$t('first_name'),
              class: "lg:w-99 w-full mb-1"
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_input_default, {
              id: 'last_name',
              name: 'last_name',
              modelValue: _ctx.user.last_name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.last_name) = $event)),
              type: 'text',
              label: _ctx.$t('last_name'),
              class: "lg:w-99 w-full mb-1"
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_input_default, {
              id: 'email',
              name: 'email',
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.email) = $event)),
              type: 'text',
              label: 'E-Mail',
              class: "lg:w-99 w-full mb-1"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_input_select, {
                id: "role_ids",
                name: "role_ids",
                label: _ctx.$t('roles'),
                isMultiSelect: true,
                selectItemConfiguration: {
                            valueColumn: 'id',
                            labelColumn: 'name',
                        },
                useValueColumnAsValue: true,
                isInputDisabled: true,
                options: _ctx.roles,
                modelValue: _ctx.user.role_ids,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.role_ids) = $event)),
                class: "mb-1"
              }, null, 8, ["label", "options", "modelValue"])
            ])
          ]),
          (_ctx.user.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_custom_button, {
                  id: 'delete-button',
                  class: "max-w-full",
                  isPlainButton: true,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openDeleteModal = true))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_icon_trash, { color: 'red' }),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("delete_user")), 1)
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_delete_modal, {
            open: _ctx.openDeleteModal,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openDeleteModal = false)),
            onDelete: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submitDeleteUser())),
            type: 'Benutzer'
          }, null, 8, ["open"])
        ]),
        _: 1
      }, 8, ["title", "cancelButtonText"]))
    : _createCommentVNode("", true)
}