import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-full w-full flex flex-col" }
const _hoisted_2 = { class: "p-2 pl-8 w-full h-full flex flex-col flex-shrink-0" }
const _hoisted_3 = { class: "md:flex justify-between w-full mb-5" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  class: "h-1/2 w-full flex items-center justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_7 = {
  key: 2,
  class: "overflow-y-auto h-full pr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_search = _resolveComponent("input-search")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_user_container = _resolveComponent("user-container")!
  const _component_add_user_modal = _resolveComponent("add-user-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t("settings.userAdministration")), 1),
        _createVNode(_component_custom_button, {
          class: "md:w-32 w-full h-10 md:mt-0 mt-5",
          id: 'add-user-button',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddUser = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_icon_plus, {
                color: 'white',
                class: "mr-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("user")), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_input_search, {
        class: "md:w-64 w-full mb-5",
        id: 'search-policy',
        name: 'search-policy',
        modelValue: _ctx.userSearch,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userSearch) = $event)),
        placeholder: _ctx.$t('name')
      }, null, 8, ["modelValue", "placeholder"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_loading_animation, {
              id: 'loadingAnimation',
              name: 'loadingAnimation',
              animationStyle: { radius: '12', animationColor: 'grey' }
            })
          ]))
        : (_ctx.filteredUsers.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (user) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: user.id,
                  class: "mt-1"
                }, [
                  _createVNode(_component_user_container, {
                    user: user,
                    onEditUser: ($event: any) => (_ctx.editUser(user)),
                    onResendInvite: ($event: any) => (_ctx.prepareResendEmail(user))
                  }, null, 8, ["user", "onEditUser", "onResendInvite"])
                ]))
              }), 128))
            ]))
    ]),
    _createVNode(_component_add_user_modal, {
      open: _ctx.openAddUser,
      editUser: _ctx.toEditUser,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openAddUser = false), (_ctx.toEditUser = {})))
    }, null, 8, ["open", "editUser"])
  ]))
}